import { RigaCarrelloFasceEta } from "./rigaCarrello";

export class ParametriCarrelloItem {
	pacchettoID: number;
	inizioViaggio: Date;
	fineViaggio: Date;
	statoID: number;
	nazionalitaID: number;
	numeroPartecipanti: number;
	tesseraSingola: boolean;
	importoViaggio: number | null;
	secondoImporto: number | null;
	numeroAlloggi: number | null;
	codicePromo: string;
	elencoPartecipanti: string;
	durataViaggio: number;
	fasceEta: RigaCarrelloFasceEta[] | null;
}